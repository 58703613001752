import React, { useState, useEffect } from 'react'
import metaIcon from '../img/icons/metamask-icon.png'
import { FileCopy } from '@material-ui/icons';

function MetaMaskConnect() {
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [isMetaMaskAvailable, setIsMetaMaskAvailable] = useState(false);

  // Check if MetaMask is available
  useEffect(() => {
    if (window.ethereum) {
      if (window.ethereum.isMetaMask) {
        setIsMetaMaskAvailable(true);
        // handleConnectMetaMask
      }
      console.log(account)
    } else {
      alert('MetaMask is not installed. Please install MetaMask to use this feature.');
    }
  }, []);

  const handleConnectMetaMask = async () => {


    if (isMetaMaskAvailable) {
      try {
        // Request Ethereum accounts
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log(accounts)
        setAccount(accounts[0]);
        console.log(accounts[0])
        // Get the balance of the first account (in Wei)
        const balanceInWei = await window.ethereum.request({
          method: 'eth_getBalance',
          params: [accounts[0], 'latest'], // 'latest' to get the current balance
        });

        // Convert balance from Wei to Ether (1 Ether = 10^18 Wei)

      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
        if (error.code === 4001) {
          // User rejected the connection
          alert('You have rejected the connection request.');
        } else {
          alert('An error occurred. Please try again.');
        }
      }
    } else {
      alert('MetaMask is not installed.');
    }
  };

  return (

    <a className="btn btn-grad btn-sm" onClick={handleConnectMetaMask}>
      <i className="ri-wallet-3-line"></i>
      {/*   */}
      {account ? (<>
        <img src={metaIcon} alt='' className='hight-24' />{' '}
        <span className='address-show'>{account.substring(0, 5)}...{account.slice(-5)}</span>
        <FileCopy className='small-icon' />
      </>) : (<><img src={metaIcon} alt='' className='hight-24' /> wallet</>)}

    </a>

  )
}

export default MetaMaskConnect